<template>
	<div id="printTemplate" class="printTemplate">
	
		<div class="main">
			<button type="button" class="download fontw-bold am-text-sm am-btn wid-100 am-block bg-btn-default">下载报告</button>
			<div id="content" class="content wid-100">
				<div class="cover">
					<div class="am-text-center">
						<div class="header-tem">
							<img class="cover-logo" src="/assets/img/print_logo.png" alt="">
							<div class="header-tem-info">
								<div class="tem-info-name">米因健康大数据体检官方平台</div>
								<div class="tem-info-web">
									<a href="https://main.medinbd.com/" target="_blank" >https://main.medinbd.com</a>
								</div>
								<div class="tem-info-desc">我们建议您通过在线浏览获得更直观的图表分析和更好的浏览体验。</div>
								<!-- <div class="">
									<img class="expo-logo" src="/assets/img/expo_logo02.png" alt="">
								</div>
								<div class="expo-title">2020迪拜世博会官方推荐及赞誉企业</div> -->
							</div>
						</div>
						<div class="cover-img relative">
							<img class="absolute wh-100" src="/assets/img/download-home-bg02.png" alt="">
							<div class="absolute download-box">
								<img class=" download-box-img " src="/assets/img/download-home-box.png" alt="">
							</div>
							<div class="absolute download-box">
								<div class="download-box-title">--</div>
								<div class="download-box-text">根据大数据分析评估，您属于</div>
								<div class="download-box-eng">According to big data analysis and evaluation，you belong to <span>--</span></div>
							</div>
						</div>
					</div>
					<div class="describe">
						<div class="examdate-cell">体检日期: <span class="examdate"></span> </div>
						<div class="examdate-cell">电话: <span class="mobile"></span></div>
					</div>
				</div>
				<div class="page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div>
						<div class="title">
							<h1 class="am-text-center">米因智慧医疗<span class="am-block">大数据体检分析与预测报告</span></h1>
							<p>
								<span class="fontw-bold">医疗大数据+人工智能：</span>是利用海量的数据对健康、疾病与死亡做出的不同的风险预测与分析，这些医疗大数据是经过持续超过2500万华人生、老、病、死的连续25年以上的健康和医疗记录组成；目的是评估人整体健康现况，预测未来健康可能演化的趋势，以提供「治未病」的改善方案，让每个人拥抱健康、远离疾病，是现今智能社区发展的大趋势。
							</p>
							<div class="am-text-right am-text-xl">
								米因智慧医疗为您评估健康风险
								<span class="am-block">——愿健康常伴您左右</span>
							</div>
						</div>
					</div>
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div>
						<div class="catalog">
							<h2>报告目录</h2>
							<ul>
								<li>一、个人基本信息</li>
								<li>二、个人问卷资料</li>
								<li>三、生化结果信息</li>
								<li>
									四、健康现状评估
									<ol>
										<li class="health_score">健康评分</li>
										<li class="total">总体健康评估和生命预测</li>
										<li class="realage">生理年龄[健康风向标]</li>
										<li class="bodily">体型评估</li>
										<li class="biom-ind">生化指标</li>
										<li class="risk-ratio">潜在核心疾病群风险评估
											<ol>
												<li class="biom-ind2">潜在核心疾病风险倍率评估</li>
												<li class="three-h">四高风险倍率评估[健康风向标]</li>
											</ol>
										</li>
									</ol>
								</li>
								<li>
									五、未来健康预测
									<ol>
										<li class="illness">未来疾病风险预测</li>
										<li class="cancel">癌症风险预测[健康风向标]</li>
										<li class="costs">医疗费用预测</li>
									</ol>
								</li>
								<li>
									六、个人健康管理
									<ol>
										<li class="no-illness">不患病&增寿</li>
										<li class="improve-bio">生化指标改善目标</li>
										<li class="improve">健康管理和改善方案</li>
									</ol>
								</li>
								<li>
									<span class="fb-catalogue"></span>、骨折评估结果
									<ol>
										<li class="fb-state">骨质状况分析</li>
										<li class="fb-risk">身体各部位骨折的风险概率</li>
										<li class="fb-future">未来五/十年骨折的概率</li>
										<li class="fb-death">骨折风险死亡率</li>
									</ol>
								</li>
								<li>
									<span class="fb-catalogue-r"></span>、骨折改善建议
									<ol>
										<li class="fb-tscore">改善建议</li>
										<li class="fb-diet">饮食改善建议</li>
									</ol>
								</li>
							</ul>
							
						</div>
					</div>
					
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<!-- <div class="username">
						您的报告解读
						<p class="date am-text-default am-text-center">日期：----年--月--号</p>
					</div>
					<div class="belong">
						根据大数据分析评估，您属于&nbsp;&nbsp;<b class="health-type">--</b>
					</div> -->
					<!--的健康评分为 <b class="grade">--</b>分, -->
					<div class="information">
						<h4>基本信息</h4>
						<ul class="basic">
							<li class="am-fl">
								<dl>
									<dt>性别</dt>
									<dd><b class="user-sex">--</b></dd>
								</dl>
							</li>
							<!-- <li class="am-fl width-li-2">
								<dl>
									<dt>出生日期</dt>
									<dd><b class="user-birth">--</b></dd>
								</dl>
							</li> -->
							<li class="am-fl">
								<dl>
									<dt>年龄</dt>
									<dd><b class="user-age">--</b>岁</dd>
								</dl>
							</li>
							<li class="am-fl">
								<dl>
									<dt>身高</dt>
									<dd><b class="height">--</b><span>cm</span></dd>
								</dl>
							</li>
							<li class="am-fl">
								<dl>
									<dt>体重</dt>
									<dd><b class="weight">--</b><span>kg</span></dd>
								</dl>
							</li>
							<li class="am-fl width-li-5">
								<dl>
									<dt>体脂率</dt>
									<dd><b class="BFR">--</b><span>%</span></dd>
								</dl>
							</li>
							<li class="am-fl border-right-no">
								<dl>
									<dt>腰围</dt>
									<dd><b class="waist">--</b><span>cm</span></dd>
								</dl>
							</li>
							<li class="am-fl border-bottom-no">
								<dl>
									<dt>臀围</dt>
									<dd><b class="hipline">--</b><span>cm</span></dd>
								</dl>
							</li>
							<li class="am-fl chest-li border-bottom-no">
								<dl>
									<dt>胸围</dt>
									<dd><b class="chest">--</b><span>cm</span></dd>
								</dl>
							</li>
							<li class="am-fl border-bottom-no">
								
							</li>
							<li class="am-fl border-bottom-no border-right-no">
								
							</li>
						</ul>
						<h4>问卷信息</h4>
						<ul class="question am-cf">
							<li class="width-33">
								<dl>
									<dt>居住地</dt>
									<dd><b class="place">--</b></dd>
								</dl>
							</li>
							<li class="width-33">
								<dl>
									<dt>是否喝酒</dt>
									<dd><b class="drink">--</b></dd>
								</dl>
							</li>
							<li class="width-33 border-right-no">
								<dl>
									<dt>是否吸烟</dt>
									<dd><b class="smoke">--</b></dd>
								</dl>
							</li>
							<li class="width-33 ">
								<dl>
									<dt>用餐习惯</dt>
									<dd><b class="eat-habit">--</b></dd>
								</dl>
							</li>
							<li class="width-33">
								<dl>
									<dt>是否是全日素食者</dt>
									<dd><b class="vegan">--</b></dd>
								</dl>
							</li>
							<li class="sport width-33 border-right-no">
								<dl>
									<dt>运动习惯</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no">
								<dl>
									<dt>是否有睡眠障碍</dt>
									<dd><b class="sleep">--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no">
								<dl class="has-illness">
									<dt>患有的疾病</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no">
								<dl class="no-medicine">
									<dt>患病而不服用药物的疾病</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no">
								<dl class="occ-medicine">
									<dt>患病而偶尔服用药物的疾病</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no">
								<dl class="often-medicine">
									<dt>患病而经常服用药物的疾病</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no">
								<dl class="operation">
									<dt>做过的手术</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no">
								<dl class="family-illness">
									<dt>直系亲属患有的疾病</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<!-- 骨折评估 -->
							<li class="fb-info width-33">
								<dl>
									<dt>曾经是否骨折</dt>
									<dd><b class="qnmh">--</b></dd>
								</dl>
							</li>
							<li class="fb-info width-33">
								<dl>
									<dt>曾经骨折次数</dt>
									<dd><b class="qntimes">--</b></dd>
								</dl>
							</li>
							<li class="fb-info  width-33 border-right-no">
								<dl>
									<dt>父母是否髋部骨折</dt>
									<dd><b class="qnpbf">--</b></dd>
								</dl>
							</li>
							<li class="fb-info width-100 border-right-no">
								<dl>
									<dt>股骨颈骨密度(BMD)</dt>
									<dd><b class="tscore">--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no fb-info">
								<dl class="has-fb ">
									<dt>患有的疾病(骨折)</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no fb-info">
								<dl class="has-selfcare">
									<dt>日常生活与事务决定处理与认知能力</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
							<li class="width-100 border-right-no border-bottom-no fb-info">
								<dl class="has-service">
									<dt>需要医护服务</dt>
									<dd><b>--</b></dd>
								</dl>
							</li>
						</ul>
						<div class="biochemistry-wrap">
							<h4>生化信息</h4>
							<ul class="biochemistry am-cf">
								
							</ul>
						</div>
					</div>
					
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				
				<div class="information biochemistry-page page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<h4>生化信息</h4>
					<ul class="biochemistry am-cf">
						
					</ul>
					<div class="page-footer relative">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<!-- 健康分 -->
				<div class="r-total page-wrap">
					<div class="health_score_page">
						<div class="page-head relative">
							<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
							<div class="absolute medin-logo-text">健康大数据评估报告</div>
							<dl class="am-text-right">
								<dt class="am-text-sm fontw-normal"></dt>
								<dd></dd>
							</dl>
						</div>
						<div class="health_score">
							<h3 class="res-tit title-before">健康评分</h3>
							<div class="new-module-explain">
								<p>米因以大数据为基础，综合个人的当前健康状况、潜在疾病风险和未来健康风险进行整体评分，给出百分制的「健康分」，以求尽可能对整体健康进行量化评估，既可用于表示个人健康状况，也可作为个人健康管理的目标和评价标准。<br><span class="fontw-bold">对于个人而言，分数越高则相对越健康，健康分不适用于85岁以上的长者。</span></p>
							</div>
							<div class="score_box">
								<div class="score_chat">
									<!-- <p>您的健康评分</p>
									<b>85</b>
									<span>分(良好)</span> -->
								</div>
								<div class="score_line_bg">
									<div class="score_one_bg"></div>
									<div class="score_d1"></div>
									<div class="score_two_bg"></div>
									<div class="score_d2"></div>
									<div class="score_three_bg"></div>
									<div class="score_line_top"></div>
								</div>
								<div class="score_line_text">健康分等级：100-90分以上视为「优等」；89-60分视作「良好」；59-1分视为「欠佳」。</div>
							</div>
							<div class="score_item_title">健康是人生的动态历程</div>
								<div class="score_health_wrap">
									<div class="score_health_list">
										<div class="score_health_item">健康</div>
										<div class="score_health_item_icon"></div>
										<div class="score_health_item">亚健康</div>
										<div class="score_health_item_icon"></div>
										<div class="score_health_item">患病者 的过程是可逆的</div>
									</div>
									<div class="score_health_text">因不良生活习惯导致患病的慢病患者，在适当的医疗介入与生活习惯改善后，完全有可能逐渐康复、不再复发。</div>
								</div>
								<div class="score_item_title">影响个人健康的因素</div>
								<div class="score_health_wrap">
									<div class="score_health_list">
										<div class="score_tag">基因遗传</div>
										<div class="score_tag">生活环境</div>
										<div class="score_tag">生活习惯</div>
										<div class="score_tag">医疗支持</div>
										<div class="score_tag">特殊因素</div>
									</div>
								</div>
								<div class="score_item_title">不同人群影响健康分的因素</div>
								<div class="score_health_wrap">
									<div class="score_type_text"><span>医疗需求人群</span> 同一或不同疾病间都有轻重程度之别，在医疗科技与健康意识进步下，疾病的可控度与可愈度已进步很多，疾病未必会造成身心失衡或影响活力，因此患病者也有可能在平稳控制病情和保持良好生活习惯的情况下，获得比较高的健康分。</div>
									<div class="score_type_text"><span>亚健康人群</span> 往往在不良生活习惯的影响下，身体已有许多潜在疾病但尚未表露，表面看似仅是忙碌劳累，实则是在透支生命与健康。这种情况下往往只需要一两次急症发作，便会全面引爆潜藏的健康危机，导致个人健康状况的迅速恶化。如果您认为自己较为健康，但却获得了不理想的健康分结果，还请您提高警惕，更多关爱自身。</div>
								</div>
								<div class="score_btn_cell">
									在人群大数据的维度中，健康分与各类疾病、重大伤病、门诊次数、医疗费用、住院天数甚至死亡率等均有高度相关性。
								</div>
								<div class="score_btn_tip">请注意，人群大数据的健康分关联度统计并不代表您的个人健康风险。<br>
			健康分是一项综合评分，每个人的具体健康风险都是不一样的，请参照本报告的相关结果了解您自身的健康状况和健康风险。</div>
						</div>
						<div class="page-footer relative">
							<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
						</div>
					</div>	
				</div>
				<!-- 总体健康评估和生命预测 -->
				<div class="r-total page-wrap ">
					<div class="transform-page">
						<div class="page-head relative">
							<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
							<div class="absolute medin-logo-text">健康大数据评估报告</div>
							<dl class="am-text-right">
								<dt class="am-text-sm fontw-normal"></dt>
								<dd>
									<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
								</dd>
							</dl>
						</div>
						
						<!-- <h2 class="classify-tit am-text-xl fontw-bold color-extr">健康现状评估</h2> -->
						
						<div class="total">
							<h3 class="res-tit title-before">总体健康评估和生命预测</h3>
							<!-- <h3 class="res-tit title-before">總體健康評估和生命預測</h3> -->
							<div class="total-summary am-cf">
								<dl class="am-fl total-summary-liftime">
									<dt class="fontw-normal">九十岁概率</dt>
									<!-- <dt class="fontw-normal">預期壽命</dt> -->
									<dd class="fontw-bold">
										<!-- <b class="total-liftime fonts-24">--</b><span>歲</span> -->
										<b class="total-liftime fonts-24">--</b><span>%</span>
									</dd>
								</dl>
								<dl class="am-fl total-summary-centenary">
									<dt class="fontw-normal">百岁概率</dt>
									<!-- <dt class="fontw-normal">百歲概率</dt> -->
									<dd class="fontw-bold">
										<b class="total-centenary fonts-24">--</b><span>%</span>
									</dd>
								</dl>
								<dl class="total-summary-disability am-fl">
									<dt class="fontw-normal">失能的平均概率</dt>
									<!-- <dt class="fontw-normal">失能的平均概率</dt> -->
									<dd class="fontw-bold">
										<b class="total-disability fonts-24">--</b><span>%</span>
									</dd>
									<!-- （失能率：指所有影响生活或自理能力的情况，如腰疼、无力、重听、视力减退、骨折、因病卧床等。） -->
								</dl>
							</div>
							<div class="result-total-chart">
								
							</div>
							<!-- <div class="margin-top-40 new-module-explain">
								<p>要「長壽」 ，更要「健康的長壽」 。AI預測指導健康促進與健康管理，達成「防未病、斷慾病、緩既病」。AI分析與改善共同作用，達致「降低失能，延長高質量生命」目標。</p>
							</div> -->
							<div class="margin-top-20 new-module-explain">
								<p>现时科技与生活环境之下，人类的寿限约120岁；但人类平均寿命约在80岁左右，距离120岁的寿限还有很大的进步空间。</p>
								<p>现在医疗科技进步，人类普遍面临的是「生命」延长了，但因为不健康而生病，将导致不便或卧床，影响生活与生命品质，造成痛苦苟延残喘的时间也可能跟着延长。我们将可能影响生活品质的时间当成「失能时间」。</p>
								<p>不生病或延缓病情，降低疾病对健康的影响，必须透过健康促进与健康管理，才能「防未病、断欲病、缓既病」，进而有效的「延长健康的余命」。</p>
							</div>
						</div>
						
						<div class="page-footer relative">
							<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
							<!-- <div class="page-footer-text">本大數據分析報告僅作為健康風險提示與健康管理的參考，並非由專業機構或醫生出具的臨床診斷結果，如果您有任何疑問與需要，請尋求專業醫護機構與人員的幫助。</div> -->
						</div>
					</div>
				</div>
				<div class="r-total page-wrap am-hide">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<!-- 生理年龄 -->
					<div class="health am-hide">
						<div class="realage am-hide">
							<h3 class="res-tit">生理年龄[健康风向标]</h3>
							<div class="module-explain">
								<p>测量综合生理年龄RealAge，目的是在希望您能体会个人健康现况，可以更注重个人健康促进，让您未来能表现的更为年轻有活力。错误或不好的生活习惯，会让人身体生理机能的年龄比实际岁数老化，并增加罹病机率和死亡机率。</p>
								<p>综合生理年龄RealAge，是经研究分析海量的健康数据库，依照个人的健康危险因子（如：生活习惯、运动饮食、….等）的多寡与影响程度，分别给予正负分来计算，换句话说：RealAge＝实际年龄±影响健康危险因子之加总值。</p>
								<p>透过综合生理年龄RealAge的检测，了解健康危险因子对自己身体生理的影响程度，未来能加强良好饮食、运动、生活习惯，确实做好健康管理、预防保健与养生，以延缓老化、降低罹病与失能机率。</p>
							</div>
							<div class="result-health-tit am-text-xs am-cf bg-fff am-text-center">
								<dl class="am-fl wid-50">
									<dd class="ac-default fontw-bold">
										<span class="age am-text-lg">--</span>岁
									</dd>
									<dt class="fontw-normal">实际年龄</dt>
								</dl>
								<dl class="am-fl wid-50">
									<dd class="ac-default fontw-bold">
										<span class="real-age am-text-lg">--</span>岁
									</dd>
									<dt class="fontw-normal">
										综合生理年龄Real Age
									</dt>
								</dl>
							</div>
							<p class="result-health-ms bg-fff am-text-sm">综合生理年龄是从医学、生物学的角度来衡量的年龄，表示身体的成长、成熟或衰老的程度。米因在千万人口的医疗健康大数据库基础上，挖掘出综合生理年龄所对应的关键指标，为您提供详细、准确的大数据分析结果。</p>
						</div>
					</div>
					<div class="page-footer relative">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<!-- 体型评估 -->
				<div class="r-bodily page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="bodily">
						<h3 class="res-tit title-before">体型评估</h3>
						<div class="result-bodily-BMI bg-fff am-fl">
							<h3 class="am-text-lg">
								1.BMI
								<!-- <span class="hint"></span> -->
							</h3>
							<div class="you">
								<div class="line relative am-text-xs am-text-right">
									<p class="sub-title BMI-you c-default am-text-sm">
									    您的BMI：<span class="BMI">--</span>
									</p>
									<div class="you-box">正常</div>
									<div class="mean-box">正常</div>
									<p class="sub-title BMI-mean c-default am-text-sm">
									   同族群的BMI：<span class="BMI-av">--</span>
									</p>
									<ul class="am-cf absolute">
										<li class="step1 bg-low wid-33 am-fl">
											<span>18.5</span>
										</li>
										<li class="step2 bg-default wid-33 am-fl">
											<span>23.0</span>
										</li>
										<li class="step3 bg-high wid-33 am-fl">
											<!-- <span>27</span> -->
										</li>
										<!-- <li class="step4 bg-danger wid-33 am-fl">
										</li> -->
									</ul>
									<ol class="line-cont am-cf absolute">
										<li class="step1 bg-low am-fl wid-33"><i></i></li>
										<li class="step2 bg-default am-fl wid-33"><i></i></li>
										<li class="step3 bg-high am-fl wid-33"></li>
									</ol>
									<ol class="line-cont-mean am-cf absolute">
										<li class="step1 bg-low am-fl wid-33"><i></i></li>
										<li class="step2 bg-default am-fl wid-33"><i></i></li>
										<li class="step3 bg-high am-fl wid-33"></li>
									</ol>
								</div>
								
							</div>
							<div class="introduce am-text-xs">
								<p>BMI指数是用体重公斤数除以身高米数平方得出的数字，是目前国际上常用的衡量人体胖瘦程度以及是否健康的一个标准。</p>
								<p>临床标准范围：&lt;18.5表示过轻，18.5～23表示正常，&gt;23表示肥胖。</p>
							</div>
						</div>
						<div class="result-bodily-WHR bg-fff am-fl">
							<h3 class="am-text-lg">
								2.腰臀比(WHR)
								<!-- <span class="hint"></span> -->
							</h3>
							<div class="you">
								<div class="line relative am-text-xs am-text-right">
									<p class="sub-title WHR-you c-default am-text-sm">
									    您的WHR：
										<span class="WHR ac-default">--</span>
									</p>
									<div class="you-box">正常</div>
									<div class="mean-box">正常</div>
									<p class="sub-title WHR-mean c-default am-text-sm">
									   同族群的WHR：<span class="WHR-av">--</span>
									</p>
									<ul class="am-cf absolute">
										<li class="step1 bg-default wid-50 am-fl">
											<span>0.92</span>
										</li>
										<li class="step2 bg-danger wid-50 am-fl">
										</li>
									</ul>
									<ol class="line-cont am-cf absolute">
										<li class="step1 am-fl wid-50"><i></i></li>
									</ol>
									<ol class="line-cont-mean am-cf absolute">
										<li class="step1 bg-default am-fl wid-50"><i></i></li>
									</ol>
								</div>
							</div>
							<div class="introduce am-text-xs">
								<p>腰围反映脂肪总量和脂肪分布的综合指标,臀围反映髋部骨骼和肌肉的发育情况。腰臀比值越大，腰腹或内脏就有可能堆积更多的脂肪。</p>
								<p>临床标准范围：男性&lt;0.92，女性&lt;0.88。</p>
							</div>
						</div>
						<div class="result-bodily-BFR bg-fff am-fl">
							<h3 class="am-text-lg">
								3.体脂率(BFR)
								<!-- <span class="hint"></span> -->
							</h3>
							<div class="you">
								<div class="line relative am-text-xs am-text-right">
									<p class="sub-title BFR-you c-default am-text-sm">
									    您的BFR：
										<span class="BFR ac-default">--</span>
									</p>
									<div class="you-box">正常</div>
									<div class="mean-box">正常</div>
									<p class="sub-title BFR-mean c-default am-text-sm">
									   同族群的BFR：<span class="BFR-av">--</span>
									</p>
									<ul class="am-cf absolute">
										<li class="step1 bg-low wid-33 am-fl">
											<span>17.0</span>
										</li>
										<li class="step2 bg-default wid-33 am-fl">
											<span>23.0</span>
										</li>
										<li class="step3 bg-danger wid-33 am-fl">
										</li>
									</ul>
									<ol class="line-cont am-cf absolute">
										<li class="step1 am-fl wid-33"><i></i></li>
										<li class="step2 am-fl wid-33"><i></i></li>
									</ol>
									<ol class="line-cont-mean am-cf absolute">
										<li class="step1 bg-low am-fl wid-33"><i></i></li>
										<li class="step2 bg-default am-fl wid-33"><i></i></li>
										<li class="step3 bg-danger am-fl wid-33"></li>
									</ol>
								</div>
								
							</div>
							<div class="introduce am-text-xs">
								<p>体脂率是指人体内脂肪重量在人体总体重中所占的比例，又称体脂百分数，它反映人体内脂肪含量的多少。</p>
								<p>肥胖会提高罹患各种疾病的风险，例如高血压、糖尿病、高血脂等。</p>
								<p>临床标准范围：男性30岁以下14~20，30岁及以上17~23；<br>女性30岁以下17~24，30岁及以上20~27。</p>
							</div>
						</div>
						<!-- 您的体脂率<span class="bodily-result-bfr ac-default">--</span>，BMI<span class="bodily-result-bmi ac-default">--</span> -->
						<div class="bodily-result am-fl">
							与同族群相比，您的体脂率<span class="bodily-result-bfr ac-default">--</span>，BMI<span class="bodily-result-bmi ac-default">--</span>，因此您的体型在同族群中属于<span class="bodily-result-belong ac-default">--</span>
						</div>
						<div class="bodily-img-list">
							<ul class="result-bodily-legend am-cf">
								<li class="result-bodily-left am-fl wid-33">
									<div class=" am-text-center">
										<!-- <p class="am-text-xs">体脂率<span>偏低</span>，根据BMI划分</p> -->
										<ol class="am-cf">
											<li data-bodily='1' class="am-fl wid-33">
												<img src="/assets/img/xiaoshou.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">消瘦</p>
												<p class="margin-top-8">体脂率偏低</p>
												<p>BMI偏低</p>
											</li>
											<li data-bodily='4' class="am-fl wid-33">
												<img src="/assets/img/jingshi.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">精实</p>
												<p class="margin-top-8">体脂率偏低</p>
												<p>BMI适中</p>
											</li>
											<li data-bodily='7' class="am-fl wid-33">
												<img src="/assets/img/jianmei.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">健美</p>
												<p class="margin-top-8">体脂率偏低</p>
												<p>BMI偏高</p>
											</li>
										</ol>
									</div>
								</li>
								<li class="result-bodily-center am-fl wid-33">
									<div class=" am-text-center">
										<!-- <p class="am-text-xs">体脂率<span>适中</span>，根据BMI划分</p> -->
										<ol class="am-cf">
											<li data-bodily='2' class="am-fl wid-33">
												<img src="/assets/img/miaotiao.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">苗条</p>
												<p class="margin-top-8">体脂率适中</p>
												<p>BMI偏低</p>
											</li>
											<li data-bodily='5' class="am-fl wid-33">
												<img src="/assets/img/shizhong.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">适中</p>
												<p class="margin-top-8">体脂率适中</p>
												<p>BMI适中</p>
											</li>
											<li data-bodily='8' class="am-fl wid-33">
												<img src="/assets/img/zhuangshuo.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">壮硕</p>
												<p class="margin-top-8">体脂率适中</p>
												<p class="">BMI偏高</p>
											</li>
										</ol>
									</div>
								</li>
								<li class="result-bodily-right am-fl wid-33">
									<div class=" am-text-center">
										<!-- <p class="am-text-xs">体脂率<span>偏高</span>，根据BMI划分</p> -->
										<ol class="am-cf">
											<li data-bodily='3' class="am-fl wid-33">
												<img src="/assets/img/yinxingfeipang.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">隐性肥胖</p>
												<p class="margin-top-8">体脂率偏高</p>
												<p>BMI偏低</p>
											</li>
											<li data-bodily='6' class="am-fl wid-33">
												<img src="/assets/img/yinxingfeipang.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">隐性肥胖</p>
												<p class="margin-top-8">体脂率偏高</p>
												<p>BMI适中</p>
											</li>
											<li data-bodily='9' class="am-fl wid-33">
												<img src="/assets/img/feipang.png" alt="">
												<p class="am-text-xs bodily-tip margin-top-8">肥胖</p>
												<p class="margin-top-8">体脂率偏高</p>
												<p>BMI偏高</p>
											</li>
										</ol>
									</div>
								</li>
							</ul>
						</div>
						<div class="bodily_tips" style="margin-bottom: 0.2rem; font-size: 0.18rem;"></div>
						<div class="new-module-explain">
							<p class="margin-bottom-12">体型（如身体质量指数BMI、腰臀比WHR、体脂率FAT等）数据常是健康与否的最重要指标之一，体型数据失衡时，往往是内在核心生化指标发生重要改变的外在表现，常是慢性病发生的先兆。临床研究指出如肥胖或过重易引起1疾患（高血压、高血脂、糖尿病、痛风）等长期慢性疾病，因此日常依据体型（即身高、体重、腰围、臀围等）评估的结果，进行个人体型的管理，对健康管理是最简单易行且助益颇大。</p>
							<p class="margin-bottom-12">米因依据逾2500万华人的健康大数据，提供跟您同健康族群（People Like Me）者的资料比较观念，评估出你的体型与同性别同年龄族群平均数；及与临床标准相互比较下，找出您的体型需维持或改善的项目（含BMI、腰臀比和体脂率）与目标，并以此为根据找出健康管理（如饮食热量、运动等）的目标与方法。</p>
							<p class="margin-bottom-12">透过体型评估，可以知道身体的警讯在哪，进而了解未来可能罹患慢性疾病的风险，加强个人健康意识，会更有动力依据团队健康管理目标，进行相关的健康促进作为。</p>
						</div>
						
					</div>
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				
				<!-- 生化指标 -->
				<div class="r-biom-ind page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="biom-ind">
						<h3 class="res-tit title-before">生化指标</h3>
						
						<div class="biom-result">
							<div class="biom-pressure">
								<div class="biom-pressure-header">
									<div class="biom-pressure-name">血压检查</div>
									<div class="biom-pressure-result">结果</div>
									<div class="biom-pressure-standard">临床标准范围</div>
									<div class="biom-pressure-standard2">与临床标准比较</div>
									<div class="biom-pressure-average">同族群平均值</div>
									<div class="biom-pressure-compare">与同类族群比较</div>
								</div>
								<div class="biom-item-wrap">
									<div class="item-cell SBP">
										<div class="biom-pressure-name item-val border-right">收缩压(SBP)</div>
										<div class="biom-pressure-result item-val border-right SBP-res">120 mmHg</div>
										<div class="biom-pressure-standard item-val border-right SBP-stand">--</div>
										<div class="biom-pressure-standard2 item-val border-right SBP-stand2">--</div>
										<div class="biom-pressure-average item-val border-right  SBP-aver">--</div>
										<div class="biom-pressure-compare item-val SBP-comp">--</div>
									</div>
									<div class="item-cell border-bottom-no DBP">
										<div class="biom-pressure-name item-val border-right">舒张压(DBP)</div>
										<div class="biom-pressure-result item-val border-right DBP-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right DBP-stand">--</div>
										<div class="biom-pressure-standard2 item-val border-right DBP-stand2">--</div>
										<div class="biom-pressure-average item-val border-right DBP-aver">--</div>
										<div class="biom-pressure-compare item-val  DBP-comp">--</div>
									</div>
								</div>
								<div class="biom-pressure-header margin-top-24">
									<div class="biom-pressure-name">血液常规检查</div>
								</div>
								<div class="biom-item-wrap">
									<div class="item-cell WBC">
										<div class="biom-pressure-name item-val border-right">白细胞(WBC)</div>
										<div class="biom-pressure-result item-val border-right WBC-res">120 mmHg</div>
										<div class="biom-pressure-standard item-val border-right WBC-stand">90～140 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right WBC-stand2">--</div>
										<div class="biom-pressure-average item-val border-right WBC-aver">115 mmHg</div>
										<div class="biom-pressure-compare item-val WBC-comp ">优良</div>
									</div>
									<div class="item-cell HGB">
										<div class="biom-pressure-name item-val border-right">血红蛋白(HGB)</div>
										<div class="biom-pressure-result item-val border-right HGB-res" >96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right HGB-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right HGB-stand2">--</div>
										<div class="biom-pressure-average item-val border-right HGB-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val HGB-comp">偏高</div>
									</div>
									<div class="item-cell border-bottom-no PLT">
										<div class="biom-pressure-name item-val border-right">血小板(PLT)</div>
										<div class="biom-pressure-result item-val border-right PLT-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right PLT-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right PLT-stand2">--</div>
										<div class="biom-pressure-average item-val border-right PLT-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val PLT-comp">偏高</div>
									</div>
								</div>
								<div class="biom-pressure-header margin-top-24">
									<div class="biom-pressure-name">血糖检查</div>
								</div>
								<div class="biom-item-wrap">
									<div class="item-cell FG">
										<div class="biom-pressure-name item-val border-right ">空腹血糖(FG)</div>
										<div class="biom-pressure-result item-val border-right FG-res">120 mmHg</div>
										<div class="biom-pressure-standard item-val border-right FG-stand">90～140 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right FG-stand2">--</div>
										<div class="biom-pressure-average item-val border-right FG-aver">115 mmHg</div>
										<div class="biom-pressure-compare item-val FG-comp ">优良</div>
									</div>
									<div class="item-cell border-bottom-no HBA1C">
										<div class="biom-pressure-name item-val border-right">糖化血红蛋白(HBA1C)</div>
										<div class="biom-pressure-result item-val border-right HBA1C-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right HBA1C-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right HBA1C-stand2">--</div>
										<div class="biom-pressure-average item-val border-right HBA1C-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val HBA1C-comp">偏高</div>
									</div>
								</div>
								<div class="biom-pressure-header margin-top-24">
									<div class="biom-pressure-name">血脂肪检查</div>
								</div>
								<div class="biom-item-wrap">
									<div class="item-cell CHOL">
										<div class="biom-pressure-name item-val border-right">总胆固醇(CHOL) </div>
										<div class="biom-pressure-result item-val border-right CHOL-res">120 mmHg</div>
										<div class="biom-pressure-standard item-val border-right CHOL-stand">90～140 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right CHOL-stand2">--</div>
										<div class="biom-pressure-average item-val border-right CHOL-aver">115 mmHg</div>
										<div class="biom-pressure-compare item-val CHOL-comp ">优良</div>
									</div>
									<div class="item-cell LDLC">
										<div class="biom-pressure-name item-val border-right">低密度脂蛋白胆固醇(LDLC)</div>
										<div class="biom-pressure-result item-val border-right LDLC-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right LDLC-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right LDLC-stand2">--</div>
										<div class="biom-pressure-average item-val border-right LDLC-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val LDLC-comp ">偏高</div>
									</div>
									<div class="item-cell HDLC">
										<div class="biom-pressure-name item-val border-right">高密度脂蛋白胆固醇(HDLC)</div>
										<div class="biom-pressure-result item-val border-right HDLC-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right HDLC-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right HDLC-stand2">--</div>
										<div class="biom-pressure-average item-val border-right HDLC-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val HDLC-comp ">偏高</div>
									</div>
									<div class="item-cell border-bottom-no TG">
										<div class="biom-pressure-name item-val border-right">甘油三酯(TG)</div>
										<div class="biom-pressure-result item-val border-right TG-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right TG-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right TG-stand2">--</div>
										<div class="biom-pressure-average item-val border-right TG-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val TG-comp ">偏高</div>
									</div>
								</div>
								<div class="biom-pressure-header margin-top-24">
									<div class="biom-pressure-name">肝胆功能检查</div>
								</div>
								<div class="biom-item-wrap">
									<div class="item-cell GPT">
										<div class="biom-pressure-name item-val border-right">谷丙转氨酶(GPT) </div>
										<div class="biom-pressure-result item-val border-right GPT-res">120 mmHg</div>
										<div class="biom-pressure-standard item-val border-right GPT-stand">90～140 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right GPT-stand2">--</div>
										<div class="biom-pressure-average item-val border-right GPT-aver">115 mmHg</div>
										<div class="biom-pressure-compare item-val GPT-comp">优良</div>
									</div>
									<div class="item-cell GOT">
										<div class="biom-pressure-name item-val border-right">谷草转氨酶(GOT)</div>
										<div class="biom-pressure-result item-val border-right GOT-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right GOT-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right GOT-stand2">--</div>
										<div class="biom-pressure-average item-val border-right GOT-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val GOT-comp ">偏高</div>
									</div>
									<div class="item-cell ALP">
										<div class="biom-pressure-name item-val border-right">碱性磷酸酶(ALP)</div>
										<div class="biom-pressure-result item-val border-right ALP-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right ALP-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right ALP-stand2">--</div>
										<div class="biom-pressure-average item-val border-right ALP-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val ALP-comp ">偏高</div>
									</div>
									<div class="item-cell border-bottom-no ALB">
										<div class="biom-pressure-name item-val border-right">血清白蛋白(ALB)</div>
										<div class="biom-pressure-result item-val border-right ALB-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right ALB-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right ALB-stand2">--</div>
										<div class="biom-pressure-average item-val border-right ALB-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val ALB-comp ">偏高</div>
									</div>
								</div>
								<div class="biom-pressure-header margin-top-24">
									<div class="biom-pressure-name">肾功能检查</div>
								</div>
								<div class="biom-item-wrap">
									<div class="item-cell CRE">
										<div class="biom-pressure-name item-val border-right">血清肌酐(CRE)</div>
										<div class="biom-pressure-result item-val border-right CRE-res">120 mmHg</div>
										<div class="biom-pressure-standard item-val border-right CRE-stand">90～140 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right CRE-stand2">--</div>
										<div class="biom-pressure-average item-val border-right CRE-aver">115 mmHg</div>
										<div class="biom-pressure-compare item-val CRE-comp ">优良</div>
									</div>
									<div class="item-cell UA">
										<div class="biom-pressure-name item-val border-right">尿酸(UA)</div>
										<div class="biom-pressure-result item-val border-right UA-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right UA-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right UA-stand2">--</div>
										<div class="biom-pressure-average item-val border-right UA-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val UA-comp ">偏高</div>
									</div>
									<div class="item-cell border-bottom-no EGFR">
										<div class="biom-pressure-name item-val border-right">肾小球过滤率(EGFR)</div>
										<div class="biom-pressure-result item-val border-right EGFR-res">96 mmHg</div>
										<div class="biom-pressure-standard item-val border-right EGFR-stand"> <90 mmHg</div>
										<div class="biom-pressure-standard2 item-val border-right EGFR-stand2">--</div>
										<div class="biom-pressure-average item-val border-right EGFR-aver">82 mmHg</div>
										<div class="biom-pressure-compare item-val EGFR-comp ">偏高</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="r-biom-ind page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="biom-ind">
						<h3 class="res-tit title-before">生化指标</h3>
						<div class="new-module-explain">
							<p>「生化检验」是检测血液中的各种离子、糖类、脂类、蛋白质以及各种酶、激素和机体的多种代谢产物的含量，作为个人健康的「参照物」来推测身体健康程度。</p>
							<p>临床上生化检验的目的在于疾病的诊断、追踪治疗效果、病情预后指标及疾病的筛检。健检时生化检验的目的在于找出未被发现的疾病，以达早期发现；以便早期治疗，提早疗效。</p>
							<p>生化检查除临床与健检在处理「既病」的相关应用外，我们更是可以利用相关生化检查数据与生活习性问卷，发现可能的「未病」与「欲病」，以阻断疾病的发生。</p>
							<p>大部分生化检验结果正常与否，均会参考其是否落在正常值范围而判定。若检验数据异常，表示某种疾病存在的机率较大；反之检验数据正常，表示某种疾病存在的机率较低。</p>
							<p> 有时候，单一的检验值虽在正常范围，但受其他健康条件的影响，或许有很大的机率无法有效的反映该检验的功能，而表现失效，也就是说任一检验反应在疾病上，都有「假阳性」与「假阴性」的问题。为避免单一检验的失效，我们利用临床数据库，综合各项检验与其他条件，建立疾病预测模型，再以您的生化检验值的结果，估算个人未来各类慢性病、癌症、重大病症、失能或死亡发生的机率，这是一般健检报告无法表现与提供的</p>
							<p>生化检验结果反应了个人健康程度，但是健康程度又与年龄、性别有关，所以多数生化检验值正常范围应随年龄、性别不同而变化，因此我们也提供同族群（People Like Me--与您相似的人群--与您同性别同年龄段的人群）的各项检验值的平均数，以找出您需维持或改善的项目与目标，作为健康管理的参考依据。现代社会生活节奏快压力大，不健康的生活方式愈发普遍，因而同族群平均值也会受到相应的影响，例如对于中老年人群，血糖、胆固醇等四高相关指标的同族群平均值有可能显著偏高，甚至超出临床标准范围，这种情况反而更加说明定期检查与健康管理的重要性。我们希望您能结合「临床标准」与「同族群平均值」两个维度，更加全面、客观的掌握自身的健康状况。</p>
						</div>
						
					</div>
					<div class="page-footer relative">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<!-- 常见疾病风险倍率 -->
				<!-- 未来健康风险预测 -->
				<div class="r-health risk-ratio am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<h3 class="classify-tit title-before res-tit color-extr fontw-bold fonts-20">潜在疾病风险评估：</h3>
					<div class="biom-ind2 wid-100">
						<div class="result-biochemical-chart wid-100 bg-fff">
							
						</div>
						<div class="rates-suggest">
							<h5 class="am-text-sm color-extr">建议进一步检查或每半年追踪检查评估的项目：（风险倍率≥2的疾病结果）</h5>
							<table class="rates-suggest-danger wid-100" border="0" cellspacing="0" cellpadding="0">
							
							</table>
						
							<h5 class="am-text-sm color-extr">建议每半年追踪检查评估的项目：（1≤风险倍率＜2的疾病结果）</h5>
							<table class="rates-suggest-waring wid-100" border="0" cellspacing="0" cellpadding="0">
							
							</table>	
						</div>
						<!-- <h5 class="res-tit fonts-18">潜在核心疾病风险倍率评估：</h5> -->
						<div class="new-module-explain">
							<p>为预测健康人群「未病」与亚健康人群「欲病」的发生、及医疗需求人群「既病」病情的演变，团队利用海量健康与临床资料，透过大数据分析建立疾病预测模式，将个人生活习性问卷、体态评估、生化检验结果评估等健康影响因子等资料，评估与预测个人可能罹患各项疾病的风险，并透过同族群People Like Me模式，得到个人罹病的风险倍率，若倍率高于1愈多，表示个人罹患该疾病可能风险愈高。</p>
							<p>罹病的风险倍率代表着健康管理与疾病管理的迫切性，透过有效且积极的管理，将能有效的降低风险倍率，以阻断「未病/欲病」的发生，或防止「既病」的进一步恶化。</p>
							<p>因此，建议当倍率大于1时，宜针对高风险疾病每半年追踪检查评估一次；当倍率大于2时，宜针对高风险疾病做进一步的检查或每半年追踪检查评估一次。</p>
						</div>
					</div>
					
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="health">
						<div class="three-h am-hide">
							<h3 class="res-tit">四高风险[健康风向标]</h3>
							<div class="new-module-explain">
								<p class="three-highs_p">心血管疾病是人类头号杀手，每年因心血管疾病死亡的人数占总死亡人数的30%以上。</p>
								<p class="three-highs_p">心血管疾病包括心脏病、中风和血管疾病等。中国的中风发病率是西方国家的4倍。</p>
								<p class="three-highs_p">由心血管疾病引发的死亡约80%发生在中低收入家庭，且多发生在人的壮年期。</p>
								<p class="three-highs_p">导致心血管疾病的主要因素就是“四高”疾病：高血压、糖尿病、高胆固醇(高血脂)和高尿酸(痛风)。</p>
								<p class="three-highs_p">“四高”疾病可能引发各种严重的并发症，除了心血管疾病以外，常见的有肾脏损害、眼底损害等，这些并发症都可能危及生命。</p>
								<p class="three-highs_p">为了身体健康和生命安全，应经常检测血压、血糖，尽量做到“早发现、早介入、早阻断”，在“四高”疾病实际发生以前降低相关风险，及时阻断或延缓相关疾病和并发症的发生。</p>
							</div>
							<ul>
								<li class="HBP_li">
									<div class="">
										<div class="three-title">高血压</div>
										<div class="three-text">患病风险是同类族群平均患病风险</div>
										<p class="fonts-20"><span class="HBP ac-default"></span></p>
									</div>
									<p class="fonts-18">
										主要症状：<span class="HBP-symptom">缓进型高血压病常见的临床症状有头痛、头晕、注意力不集中、记忆力减退、肢体麻木、夜尿增多、心悸、胸闷、乏力等；急进型高血压和高血压危重症会出现剧烈头痛、呕吐、心悸、眩晕等症状，严重时会发生神志不清、抽搐，并在短期内发生严重的心、脑、肾等器官的损害和病变，如中风、心梗、肾衰等。</span>
									</p>
								</li>
								<li class="HBG_li">
									<div class="">
										<div class="three-title">高血糖</div>
										<div class="three-text">患病风险是同类族群平均患病风险</div>
										<p class="fonts-20"><span class="HBG ac-default"></span></p>
									</div>
									<p class="fonts-18">
										主要症状：<span class="HBG-symptom">各种组织，特别是眼、肾、心脏、血管、神经的慢性损害、功能障碍，常见症状包括多饮、多尿、多食和体重减轻，也可引起一些严重危害健康的并发症。</span>
									</p>
								</li>
								<li class="HBL_li">
									<div class="">
										<div class="three-title">高血脂</div>
										<div class="three-text">患病风险是同类族群平均患病风险</div>
										<p class="fonts-20"><span class="HBL ac-default"></span></p>
									</div>
									<p class="fonts-18">
										主要症状：<span class="HBL-symptom">血脂水平过高，可直接引起一些严重危害人体健康的疾病，如动脉粥样硬化、冠心病、胰腺炎等。</span>
									</p>
								</li>
								<li class="GOUT_li">
									<div class="">
										<div class="three-title">痛风</div>
										<div class="three-text">患病风险是同类族群平均患病风险</div>
										<p class="fonts-20"><span class="GOUT ac-default"></span></p>
									</div>
									<p class="fonts-18">
										主要症状：<span class="GOUT-symptom">一种常见且复杂的关节炎，可导致关节疼痛、水肿、炎症、关节变形、肾结石、肾衰竭等症状。</span>
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- 未来疾病风险预测 -->
				<div class="r-illness page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					
					<h2 class="classify-tit am-text-xl title-before fontw-bold color-extr">未来健康预测</h2>
					
					<div class="illness">
						<h3 class="res-tit margin-top-4">未来疾病风险预测—<span class="illness-class"></span></h3>
						
						<div class="illness-list">
							<div class="illness-item">
								<div class="illness-item-title"></div>
								<div class="illness-item-echart"></div>
								<div class="illness-item-text"></div>
							</div>
							<div class="illness-item-one">
								<div class="illness-item-title"></div>
								<div class="illness-item-one-cell flex">
									<div class="illness-item-one-y">
										<div class="illness-item-one-title"></div>
										<div class="illness-item-one-y-value"></div>
									</div>
									<div class="illness-item-one-g">
										<div class="illness-item-one-title"></div>
										<div class="illness-item-one-y-value"></div>
									</div>
								</div>
								<div class="illness-item-text"></div>
							</div>
						</div>
						<div class="new-module-explain">
						
						</div>
						<!-- <div class="table-list1 table-list am-cf flex-wrap">
							
						</div> -->
					</div>
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="r-illness page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="illness margin-top-40">
						<div class="illness-list2">
							<div class="illness-item">
								<div class="illness-item-echart2"></div>
								<div class="illness-item-text"></div>
							</div>
						</div>
						<div class="new-module-explain">
						
						</div>
					</div>
					<!-- <div class="table-list2 table-list am-cf">
						
					</div> -->
					
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				
				<!-- 癌症风险 -->
				<div class="cancel am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<h2 class="classify-tit am-text-xl title-before fontw-bold color-extr">未来健康风险预测</h2>
					<h3 class="res-tit margin-top-4">癌症风险[健康风向标]</h3>
					<div class="cancel-illness-list">
						<div class="cancel-illness-item">
							<div class="cancel-illness-item-title"></div>
							<div class="cancel-illness-item-echart"></div>
							<div class="cancel-illness-item-text"></div>
						</div>
					</div>
					<div class="new-module-explain">
						<p>根据临床数据研究，癌症常是一种或多种「生活习惯病」日积月累形成「癌症病根」，在个人免疫力或健康不佳时，病根就会适时浮现为「癌症确诊」。因此癌症的防治，除须有良好的健康管理，增强体力与免疫力；慢性病患更需体会各既有慢性病对癌症可能触发的影响，了解各项慢性病的发展，并确实做好慢性病的管理，以有效降低癌症发生的风险。</p>
						<p>罹患癌症不仅影响生活品质，甚至威胁生命。但癌症不可怕，依据科学的方法早期发现「癌症病根」，早期阻断；或早期发现，早期治疗，常可获得控制，延长寿命。</p>
					</div>
					<div class="cancel-table table-list am-cf">
						
					</div>
					
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="cancel am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<h2 class="classify-tit am-text-xl title-before fontw-bold color-extr">未来健康风险预测</h2>
					<h3 class="res-tit margin-top-4">癌症风险[健康风向标]</h3>
					
					<div class="cancel-illness-list2">
						<div class="cancel-illness-item">
							<div class="cancel-illness-item-title"></div>
							<div class="cancel-illness-item-echart"></div>
							<div class="cancel-illness-item-text"></div>
						</div>
					</div>
					<div class="new-module-explain">
						<p>根据临床数据研究，癌症常是一种或多种「生活习惯病」日积月累形成「癌症病根」，在个人免疫力或健康不佳时，病根就会适时浮现为「癌症确诊」。因此癌症的防治，除须有良好的健康管理，增强体力与免疫力；慢性病患更需体会各既有慢性病对癌症可能触发的影响，了解各项慢性病的发展，并确实做好慢性病的管理，以有效降低癌症发生的风险。</p>
						<p>罹患癌症不仅影响生活品质，甚至威胁生命。但癌症不可怕，依据科学的方法早期发现「癌症病根」，早期阻断；或早期发现，早期治疗，常可获得控制，延长寿命。</p>
					</div>
					<div class="cancel-table table-list am-cf">
						
					</div>
					<div class="page-footer relative">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<!-- 医疗费用预测 -->
				<div class="r-costs page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="costs">
						<h2 class="classify-tit am-text-xl title-before fontw-bold color-extr">未来健康预测</h2>
						<h3 class="res-tit margin-top-4">医疗费用预测</h3>
						<div class="costs-summary am-cf">
							<dl class="future am-fl">
								<dd class="fonts-18">未来<span class="year-length"></span>年医疗费用预估</dd>
								<dt>
									<b class="fonts-26">--</b>
									<span>元</span>
								</dt>
							</dl>
							<dl class="lifetime am-fl">
								<dd class="fonts-18">终生累计医疗费用预估</dd>
								<dt>
									<b class="fonts-26">--</b>
									<span>元</span>
								</dt>
							</dl>
						</div>
						<div class="costs-summary-echart">
							
						</div>
						<div class="new-module-explain">
							<p>健康问题除会造成生命损失外，也会形成个人与家庭的经济压力。利用疾病现况评估与预测，可知个人在未来可能发生的慢性疾病、主要急重症或癌症，团队可预估测未来数年医疗使用（如门诊、住院、手术、用药等）的频率与相关费用趋势；从趋势图中，可体会健康促进、健康管理与疾病管理的重要性，持续不断健康促进，除可以改善生活(命)的品质，也能减轻或消除不必要的医疗费用负担。</p>
							<p>从医疗费用的变化趋势与相关的健康评估预测，或可供做个人保险、投资理财等生涯规划之参考。</p>
							<p>设算方式是以大陆主要城市（上海、深圳等地）及香港、台湾公开的统计数据，包含 GDP(国民生产总值)、NHE(国民医疗保健支出)、SHA(医疗卫生总开支帐目系统) ，比较历年数据的差异，定义各城市的相对权重，再依相关费用资料为基准，进行设算。</p>
							<p>社会、经济环境快速改变，个人消费水平差距悬殊，此医疗费用预测结果仅取代表最大部分人的医疗健康“基础”支出数据，仅供参考。</p>
						</div>
					</div>
					
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				
				<!-- 不患病&增寿 --> 
				<div class="r-no-illness am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
				
					<h2 class="classify-tit title-before am-text-xl fontw-bold color-extr">个人健康管理</h2>
					
					<div class="no-illness">
						<h3 class="res-tit margin-top-4">不患病&增寿</h3>
						<div class="illness-pressure-header">
							<div class="illness-pressure-name">原发性疾病</div>
							<div class="illness-pressure-result">最高风险概率</div>
							<div class="illness-pressure-standard">疾病影响最高年岁</div>
							<div class="illness-pressure-compare">不患病可增寿年数</div>
						</div>
						<div class="illness-item-wrap primary-wrap">
							
						</div>
						<div class="illness-pressure-header margin-top-24">
							<div class="illness-pressure-name">并发性疾病</div>
						</div>
						<div class="illness-item-wrap concurrency-wrap">
						</div>
						<div class="new-module-explain">
							<p class="no-illness-p"> 例如：<span class="illness_li_1">癌症（恶性肿瘤）</span> ，最高风险概率<span class="illness_li_2" >9</span>%，疾病影响最高年岁<span class="illness_li_3">80</span>岁，不患病可增寿年数<span class="illness_li_4">5.62</span>年—指的是对于该用户来说，其<span class="illness_li_1">癌症（恶性肿瘤）</span>的患病概率会在<span class="illness_li_3">80</span>岁之前持续上升，在<span class="illness_li_3">80</span>岁时达到最高的<span class="illness_li_2">9</span>%，在<span class="illness_li_3">80</span>岁之后则转为下降；若终生不得<span class="illness_li_1">癌症（恶性肿瘤）</span>，则预期可增寿<span class="illness_li_4">5.62</span>年。</p>
							<p>许多慢性病是生活习惯不良所引起，故又称为生活习惯病或文明病。疾病会影响寿命长短，若能降低罹患风险，寿命就会相应延长。 决定长寿的因素很多，长寿不是一早注定的，而是通过自己的长期坚持与努力获得的。只要控制好健康风险，任何人都可以比较健康长寿。在此部分给出的，是基于相关疾病，针对您的性别、年龄、基本健康风险等基本情况，计算各年龄的患病概率，再找出其中患病概率最高的岁数。</p>
						</div>
					</div>
					
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				
				<!-- 健康管理和改善方案 -->
				<div class="r-improve am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					
					<div class="improve-bio">
						<h3 class="res-tit title-before">生化指标改善</h3>
						<div class="improve-biom-pressure">
							<div class="improve-biom-pressure-header">
								<div class="improve-biom-pressure-name">血压检查</div>
								<div class="improve-biom-pressure-result">检查结果</div>
								<div class="improve-biom-pressure-average">健康风险</div>
								<div class="improve-biom-pressure-compare">短期改善目标</div>
							</div>
							<div class="improve-biom-item-wrap">
								<div class="item-cell SBP">
									<div class="improve-biom-pressure-name item-val border-right">收缩压(SBP)</div>
									<div class="improve-biom-pressure-result item-val border-right SBP-res">120 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right  SBP-aver">--</div>
									<div class="improve-biom-pressure-compare item-val SBP-comp">--</div>
								</div>
								<div class="item-cell border-bottom-no DBP">
									<div class="improve-biom-pressure-name item-val border-right">舒张压(DBP)</div>
									<div class="improve-biom-pressure-result item-val border-right DBP-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right DBP-aver">--</div>
									<div class="improve-biom-pressure-compare item-val  DBP-comp">--</div>
								</div>
							</div>
							<div class="improve-biom-pressure-header margin-top-24">
								<div class="improve-biom-pressure-name">血液常规检查</div>
							</div>
							<div class="improve-biom-item-wrap">
								<div class="item-cell WBC">
									<div class="improve-biom-pressure-name item-val border-right">白细胞(WBC)</div>
									<div class="improve-biom-pressure-result item-val border-right WBC-res">120 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right WBC-aver">115 mmHg</div>
									<div class="improve-biom-pressure-compare item-val WBC-comp ">优良</div>
								</div>
								<div class="item-cell HGB">
									<div class="improve-biom-pressure-name item-val border-right">血红蛋白(HGB)</div>
									<div class="improve-biom-pressure-result item-val border-right HGB-res" >96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right HGB-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val HGB-comp">偏高</div>
								</div>
								<div class="item-cell border-bottom-no PLT">
									<div class="improve-biom-pressure-name item-val border-right">血小板(PLT)</div>
									<div class="improve-biom-pressure-result item-val border-right PLT-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right PLT-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val PLT-comp">偏高</div>
								</div>
							</div>
							<div class="improve-biom-pressure-header margin-top-24">
								<div class="improve-biom-pressure-name">血糖检查</div>
							</div>
							<div class="improve-biom-item-wrap">
								<div class="item-cell FG">
									<div class="improve-biom-pressure-name item-val border-right ">空腹血糖(FG)</div>
									<div class="improve-biom-pressure-result item-val border-right FG-res">120 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right FG-aver">115 mmHg</div>
									<div class="improve-biom-pressure-compare item-val FG-comp ">优良</div>
								</div>
								<div class="item-cell border-bottom-no HBA1C">
									<div class="improve-biom-pressure-name item-val border-right">糖化血红蛋白(HBA1C)</div>
									<div class="improve-biom-pressure-result item-val border-right HBA1C-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right HBA1C-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val HBA1C-comp">偏高</div>
								</div>
							</div>
							<div class="improve-biom-pressure-header margin-top-24">
								<div class="improve-biom-pressure-name">血脂肪检查</div>
							</div>
							<div class="improve-biom-item-wrap">
								<div class="item-cell CHOL">
									<div class="improve-biom-pressure-name item-val border-right">总胆固醇(CHOL) </div>
									<div class="improve-biom-pressure-result item-val border-right CHOL-res">120 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right CHOL-aver">115 mmHg</div>
									<div class="improve-biom-pressure-compare item-val CHOL-comp ">优良</div>
								</div>
								<div class="item-cell LDLC">
									<div class="improve-biom-pressure-name item-val border-right">低密度脂蛋白胆固醇(LDLC)</div>
									<div class="improve-biom-pressure-result item-val border-right LDLC-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right LDLC-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val LDLC-comp ">偏高</div>
								</div>
								<div class="item-cell HDLC">
									<div class="improve-biom-pressure-name item-val border-right">高密度脂蛋白胆固醇(HDLC)</div>
									<div class="improve-biom-pressure-result item-val border-right HDLC-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right HDLC-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val HDLC-comp ">偏高</div>
								</div>
								<div class="item-cell border-bottom-no TG">
									<div class="improve-biom-pressure-name item-val border-right">甘油三酯(TG)</div>
									<div class="improve-biom-pressure-result item-val border-right TG-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right TG-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val TG-comp ">偏高</div>
								</div>
							</div>
							<div class="improve-biom-pressure-header margin-top-24">
								<div class="improve-biom-pressure-name">肝胆功能检查</div>
							</div>
							<div class="improve-biom-item-wrap">
								<div class="item-cell GPT">
									<div class="improve-biom-pressure-name item-val border-right">谷丙转氨酶(GPT) </div>
									<div class="improve-biom-pressure-result item-val border-right GPT-res">120 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right GPT-aver">115 mmHg</div>
									<div class="improve-biom-pressure-compare item-val GPT-comp">优良</div>
								</div>
								<div class="item-cell GOT">
									<div class="improve-biom-pressure-name item-val border-right">谷草转氨酶(GOT)</div>
									<div class="improve-biom-pressure-result item-val border-right GOT-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right GOT-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val GOT-comp ">偏高</div>
								</div>
								<div class="item-cell ALP">
									<div class="improve-biom-pressure-name item-val border-right">碱性磷酸酶(ALP)</div>
									<div class="improve-biom-pressure-result item-val border-right ALP-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right ALP-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val ALP-comp ">偏高</div>
								</div>
								<div class="item-cell border-bottom-no ALB">
									<div class="improve-biom-pressure-name item-val border-right">血清白蛋白(ALB)</div>
									<div class="improve-biom-pressure-result item-val border-right ALB-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right ALB-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val ALB-comp ">偏高</div>
								</div>
							</div>
							<div class="improve-biom-pressure-header margin-top-24">
								<div class="improve-biom-pressure-name">肾功能检查</div>
							</div>
							<div class="improve-biom-item-wrap">
								<div class="item-cell CRE">
									<div class="improve-biom-pressure-name item-val border-right">肌酸酐(CRE)</div>
									<div class="improve-biom-pressure-result item-val border-right CRE-res">120 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right CRE-aver">115 mmHg</div>
									<div class="improve-biom-pressure-compare item-val CRE-comp ">优良</div>
								</div>
								<div class="item-cell UA">
									<div class="improve-biom-pressure-name item-val border-right">尿酸(UA)</div>
									<div class="improve-biom-pressure-result item-val border-right UA-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right UA-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val UA-comp ">偏高</div>
								</div>
								<div class="item-cell border-bottom-no EGFR">
									<div class="improve-biom-pressure-name item-val border-right">肾小球过滤率(EGFR)</div>
									<div class="improve-biom-pressure-result item-val border-right EGFR-res">96 mmHg</div>
									<div class="improve-biom-pressure-average item-val border-right EGFR-aver">82 mmHg</div>
									<div class="improve-biom-pressure-compare item-val EGFR-comp ">偏高</div>
								</div>
							</div>
						</div>
						<p class="improve-bio-explain am-text-sm">
							<i class="iconfont iconxiaolian"></i><span>表示：良好</span>
							<i class="iconfont iconbukubuxiaolian"></i><span>表示：一般</span>
							<i class="iconfont iconkulian c-danger"></i><span>表示：需要改善</span>
						</p>
					</div>
					
					<div class="page-footer">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="r-improve am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					
					<div class="improve-bio">
						<h3 class="res-tit title-before">生化指标改善</h3>
						<div class="new-module-explain">
							<p>由于生化指标常是身体或疾病的内在表征，故对于改善生化指标应以整体而言之，即通过饮食运动改善或治疗疾病获得改善。整体健康风险得到改善，个人许多关键指标也会随之得到改善，当然这种改善是一定范围内的，毕竟大部分疾病是不“可逆”的，许多风险从量变到达质变后，便很难逆转并恢复原状，但可以通过合理手段持续控制。因此，尽可能早的、经常性的身体检查结合大数据评估，便是最佳的发现与应对手段。</p>
							<p>“早发现、早介入、早阻断”，用科学的办法将风险控制在一定水平，并结合客制化可穿戴或居家设备实时监测健康风险，可在目前科技手段下将疾病风险或重症发作的风险降至最低。</p>
							<p>关于单一项生化指标的改善内容，建议可参考健检或专业医疗机构的建议资讯，并于 3-6 个月周期内持续追踪；另外，也可请专业医师确认原因，遵循其诊断和意见。</p>
							<p>生化指标会经常发生改变，代表着健康状况和健康风险发生了变化。定期使用大数据进行分析，及时发现问题，预防疾病，掌握健康。</p>
						</div>
					</div>
					<div class="page-footer relative">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="r-improve am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="/assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="improve">
						<h3 class="res-tit title-before">健康管理和改善方案</h3>
						<div class="improve-bodily">
							<h4 class="fonts-18 res-tit margin-top-4">体型的改善</h4>
							<h4 class="res-tit summary_s_title left-title">您的专属计划方案</h4>
							<ul class="improve-bodily-summary1 am-cf">
								<li class="am-fl">
									<dl>
										<dd class="fonts-20">计划时长</dd>
										<dt>
											<b class="duration fonts-24">--</b><span class="am-text-sm">周</span>
										</dt>
									</dl>
								</li>
								<li class="am-fl">
									<dl>
										<dd class="fonts-20">平均每日饮食热量</dd>
										<dt>
											<b class="diet fonts-24">--</b><span class="am-text-sm">大卡</span>
										</dt>
									</dl>
								</li>
								<li class="am-fl">
									<dl>
										<dd class="fonts-20">建议热量改善目标</dd>
										<dt>
											<b class="suggest fonts-24">--</b><span class="am-text-sm">大卡/日</span>
										</dt>
									</dl>
								</li>
								<li class="am-fl">
									<dl>
										<dd class="fonts-20">建议运动次数</dd>
										<dt>
											<b class="sport-times fonts-24">--</b><span class="am-text-sm">次/周</span>
										</dt>
									</dl>
								</li>
							</ul>
							<div class="weight_chart_title">体重改善目标</div>
							<div class="weight_chart"></div>
							<div class="weight_chart_title">腰臀比改善目标</div>
							<div class="whr_chart"></div>
							
							<div class="new_bmi">
								<!--BMI 体型 -->
								<div class="new_bmi_cell">您的BMI是 <span class="new_bmi_val"></span>，属于 <span class="new_bmi_b"></span>型；</div>
								<!-- 理想体重 -->
								<div class="new_bmi_cell">您的理想体重为 <span class="new_bmi_lx bmi_lx1" ></span> ~ <span class="new_bmi_lx bmi_lx2"></span>公斤；</div>
								<!--运动和饮食改善 -->
								<div class="new_bmi_p">体重为影响健康的最重要指标之一，过胖或过瘦有可能对健康造成危害，导致疾病的发生，应注意体重的维持及改善，通过运动及饮食来维持及改善体重最为理想，依据您的BMI与理想体重，建议您应<span class="new_bmi_p_v"></span>，及<span class="new_bmi_p_v2"></span>;</div>
								
							</div>
						</div>
						<div class="page-footer relative">
							<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
						</div>
					</div>
				</div>
				<div class="r-improve am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="improve">
						<!-- <h3 class="res-tit title-before">健康管理和改善方案</h3> -->
						<div class="improve-bodily">
							<div class="improve-bodily-wrap">
								<div class="improve-bodily-wrap-left">
									<div class="left-title">饮食部分建议</div>
									<div class="">
										<div class="improve_ka_cell">
											<div class="improve_ka_vege">
												饮食部分建议先<b class="improve_c0" ></b>肉类<span class="meat_ka"></span>，
												再<b class="improve_c0"></b>主食<span class="staple_ka"></span>,
												豆类<span class="beans_ka"></span>，
												水果<span class="fruits_ka"></span>，
												乳制品<span class="cream_ka"></span>。
											</div>
											<div class="improve_ka_vege_2 am-hide">
												饮食部分建议先<b class="improve_c0"></b>主食<span class="staple_ka"></span>,
												豆类<span class="beans_ka"></span>，
												水果<span class="fruits_ka"></span>，
												乳制品<span class="cream_ka"></span>。
											</div>
										</div>
										<div class="left-cell-list">
											<table class="eat-table am-text-sm wid-100" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td rowspan="5" style="width: 1.6rem;">
														主食类（择一）：
													</td>
													<!-- <td class="staple">--</td> -->
												</tr>
												<tr>
													<td>
														<p class="improve_show_mf">以米饭为主食者，每天请<span class="improve_c0"></span><b class="staple-improve_mf">--</b></p>
													</td>
												</tr>
												<tr>
													<td>
														<p class="improve_show_mt">以面条为主食者，每天请<span class="improve_c0"></span><b class="staple-improve_mt">--</b></p>
													</td>
												</tr>
												<tr>
													<td>
														<p class="improve_show_zl">以杂粮为主食者，每天请<span class="improve_c0"></span><b class="staple-improve_zl">--</b></p>
													</td>
												</tr>
												<tr>
													<td>
														<p class="improve_show_mb">以面包为主食者，每天请<span class="improve_c0"></span><b class="staple-improve_mb">--</b></p>
													</td>
												</tr>
												<tr class="meat_tr am-hide">
													<td rowspan="4">
														肉类（择一）：
													</td>
													<!-- <td class="meat">--</td> -->
													
												</tr>
												<tr class="meat_tr am-hide">
													<td>
														<p class="improve_show_jc">指牛肉、猪肉、羊肉家畜等，每天请<span class="improve_c0"></span><b class="meat-improve_jc">--</b></p>
													</td>
												</tr>
												<tr class="meat_tr am-hide">
													<td>
														<p class="improve_show_hx">指鱼、虾、蟹，海鲜等，每天请<span class="improve_c0"></span><b class="meat-improve_hx">--</b></p>
													</td>
												</tr>
												<tr class="meat_tr am-hide">
													<td>
														<p class="improve_show_hx">指鸡肉、鸭肉，家禽等，每天请<span class="improve_c0"></span><b class="meat-improve_hx">--</b></p>
													</td>
												</tr>
												
												<tr>
													<td>
														蔬菜类：
													</td>
													<td>
														<p class="vegetables-tr">
															<span>指的是叶菜（如菠菜、高丽菜）、花菜（如花椰菜、金针花）、海菜（如紫菜、海带）、菇类（如香菇、洋菇）等，每日宜多变化并以当季新鲜为主。每天请 <span style="color: rgb(254, 152, 0)">增加</span><b class="vegetables-improve">--</b></span>
														</p>
													</td>
												</tr>
												
												<tr>
													<td>
														豆类：
													</td>
													<td>
														<p class="beans-tr">
															<span>指的是提供丰富植物性蛋白质的黄豆、毛豆、黑豆、其他豆类及其制品，如豆腐、无糖豆浆、小方豆干等。每天请<span class="improve_c0"></span><b class="beans-improve">--</b></span>
														</p>
													</td>
												</tr>
												<tr>
													<td>
														水果类：
													</td>
													<td>
														<p class="fruits-tr">
															<span>不含蔬菜。每天请<span class="improve_c0"></span><b class="fruits-improve">--</b></span>
														</p>
													</td>
												</tr>
												<tr>
													<td>
														乳脂类：
													</td>
													<td>
														<p class="cream-tr">
															<span>指的是鲜乳、保久乳、优酪乳及乳酪等乳制品。每天请<span class="improve_c0"></span><b class="cream-improve">--</b></span>
														</p>
													</td>
												</tr>
											</table>
											
											<div class="improve-bodily-tips">说明：1、一份约一个成人拳头或手掌大<p>2、一杯约500cc说明</p></div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
						<div class="page-footer relative">
							<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
						</div>
					</div>
				</div>
				<div class="r-improve am-hide page-wrap">
					<div class="page-head relative">
						<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
						<dl class="am-text-right">
							<dt class="am-text-sm fontw-normal"></dt>
							<dd>
								<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
							</dd>
						</dl>
					</div>
					<div class="improve">
						<h3 class="res-tit title-before">运动部分建议</h3>
						<div class="improve-bodily-wrap-right">
							<div class="left-title">运动部分建议</div>
							
							<div class="improve-bodily-sport-list ">
								<div class="sport-item low">
									<div class="sport-item-title">低强度运动（每周）</div>
									<div class="sport-item-time">30分钟</div>
									<div class="sport-item-value s1">次</div>
								</div>
								<div class="sport-item-tips">如散步、做家事、逛街、遛狗等；</div>
								<div class="sport-item middle">
									<div class="sport-item-title">中强度运动（每周）</div>
									<div class="sport-item-time">30分钟</div>
									<div class="sport-item-value s2">次</div>
								</div>
								<div class="sport-item-tips">如快步走、有氧运动、打太极拳、骑脚踏车、打高尔夫球、爬楼梯、跳舞、打桌球、骑马等；</div>
								<div class="sport-item tall">
									<div class="sport-item-title">高强度运动（每周）</div>
									<div class="sport-item-time">30分钟</div>
									<div class="sport-item-value s3">次</div>
								</div>
								<div class="sport-item-tips">游泳、打网球、打篮球、跑步、仰卧起坐、滑雪、跳绳等。</div>
								<div class="bodily-sport-tips">运动项目依据强度，分为低强度、中强度与高强度，各强度与运动项目可参考下列所述，建议根据个人作息时间与身心负担，进行适当的弹性调整。</div>
							</div>
						</div>
						<div class="new-module-explain margin-top-24">
							<p>不改变不良的生活习惯，健康将难以有效改善，导致疾病，甚至疾病会恶化。</p>
							<p>WHO指出慢性病（含癌症）四大危险因子为烟、酒、不健康饮食及缺乏规律运动；公共卫生也强调「预防」重于治疗；国内外研究证实，「预防从基因、行为和饮食」三面做起。</p>
							<p>预防重点在于防止新型致病因子------肥胖或过重，而肥胖或过重又与糟糕的生活习惯、不健康的饮食和运动不足有关。所以要健康促进，应有具体的数据监测与改善致胖环境，对多数人而言，简捷有效且易于追踪管理。</p>
							<p>
								那么如何做好健康管理？<br>
								1.养成运动的习惯：美国医界报告，提出运动可以活化体内的血液循环，提高「自然杀手细胞」的活性，增加体力与免疫力，甚至可以抑制肿瘤细胞，对抗病症。选择有兴趣且能力可及的运动，纵然体力不济者，如果能经常快走也是能达到运动的效果。<br>
								2.不可饮食过度：日常吃饭应维持八分饱；如有过重问题时，每一餐都摄取比「自己所设想」的量，再少10～30％的食物，且需注意饮食的均衡与热量摄取的控制。<br>
								3.生活规律：「日出而作，日落而息。」顺着「大自然的节奏」来活动，是最符合养生之道的，并保持之。
							</p>
							<p>我们提供客制化个人所需的健康促进方式，透过表格可知您该如何设立量化的标准，以改善您的体型、生化值数据、饮食和运动习惯。经过调整饮食、改善生活作息、培养运动习惯等方式，参照米因大数据为您量化的目标，进行有效的建康管理，达到健康促进的目的。</p>
						</div>
					</div>
					<div class="page-footer relative">
						<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
					</div>
				</div>
				<div class="r-fb page-wrap">
						<div class="page-head relative">
							<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
							<dl class="am-text-right">
								<dt class="am-text-sm fontw-normal"></dt>
								<dd>
									<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
								</dd>
							</dl>
						</div>
						<h2 class="classify-tit title-before am-text-xl fontw-bold color-extr">骨密度风险评估</h2>
						<div class="fb-state">
							<h3 class="res-tit margin-top-4">骨质状况分析</h3>
							<div class="fb-state-list">
								<div class="fb-state-item gz-zz">
									<img class="fb-state-img " src="/assets/img/gz-zz.png" alt="">
									<div class="fb-state-item-title">骨质正常</div>
								</div>
								<div class="fb-state-item gz-bz">
									<img class="fb-state-img" src="/assets/img/gz-bz.png" alt="">
									<div class="fb-state-item-title">骨质不足</div>
								</div>
								<div class="fb-state-item gz-ss">
									<img class="fb-state-img" src="/assets/img/gz-ss.png" alt="">
									<div class="fb-state-item-title">骨质疏松</div>
								</div>
							</div>
						</div>
						<div class="fb-risk">
							<h3 class="res-tit margin-top-24">骨折的概率</h3>
							<div class="fb-risk-wrap">
								<div class="fb-risk-left">
									<div class="probability-five ">
										<div class="title">未来五年</div>
										<div class="item flex ">
											<div class="item-title">骨折的风险概率</div>
											<div class="slider1"></div>
										</div>
										<div class="item flex">
											<div class="item-title">骨折复发的风险概率</div>
											<div class="slider2"></div>
										</div>
										<div class="item flex">
											<div class="item-title">骨折复发多次的风险概率</div>
											<div class="slider3"></div>
										</div>
									</div>
									<div class="probability-five">
										<div class="title">未来十年</div>
										<div class="item flex">
											<div class="item-title">骨折的风险概率</div>
											<div class="slider4"></div>
										</div>
										<div class="item flex">
											<div class="item-title">骨折复发的风险概率</div>
											<div class="slider5"></div>
										</div>
										<div class="item flex">
											<div class="item-title">骨折复发多次的风险概率</div>
											<div class="slider6"></div>
										</div>
									</div>
								</div>
								<div class="fb-risk-right">
									<div class="fb-risk-right-title">
										如果发生骨折，
										<br>身体各部位发生骨折的风险概率
									</div>
									<div class="fb-man">
										<div class="fb-man-box">
											<img class="fb-man-img" src="/assets/img/gz-man.png" alt="">
											<div class="fb-circle fb-tb"></div>
											<div class="fb-circle fb-jz"></div>
											<div class="fb-circle fb-sz"></div>
											<div class="fb-circle fb-xz"></div>
											<div class="fb-tb-box">
												<img class="fb-tb-img" src="/assets/img/gz-tb.png" alt="">
												<div class="fb-tb-info">
													头部<br>
													<span class="info-value">4</span>
													<span class="unit">%</span>
												</div>
											</div>
											<div class="fb-jz-box">
												<img class="fb-jz-img" src="/assets/img/gz-jz.png" alt="">
												<div class="fb-jz-info">
													颈椎部<br>
													<span class="info-value">25</span>
													<span class="unit">%</span>
												</div>
											</div>
											<div class="fb-sz-box">
												<img class="fb-sz-img" src="/assets/img/gz-sz.png" alt="">
												<div class="fb-sz-info">
													上肢<br>
													<span class="info-value">36</span>
													<span class="unit">%</span>
												</div>
											</div>
											<div class="fb-xz-box">
												<img class="fb-xz-img" src="/assets/img/gz-xz.png" alt="">
												<div class="fb-xz-info">
													下肢<br>
													<span class="info-value">42</span>
													<span class="unit">%</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="fb-risk">
							<div class="res-tit">因骨折导致严重伤害的风险概率</div>
							<div class="die-chart-box flex">
								<div class="die-echart"></div>
								<div class="tips">
									<div class="tip-item">
										<div class="tip-title">
											年龄 < 65岁:
										</div>
										<p class="">仅显示5年内的因骨折导致严重伤害的风险概率</p>
									</div>
									<div class="tip-item">
										<div class="tip-title">
											年龄65～74岁:
										</div>
										<p class="">显示3，5年内的因骨折导致严重伤害的风险概率；</p>
									</div>
									<div class="tip-item">
										<div class="tip-title">
											年龄75岁以上:
										</div>
										<p class="">显示2，3，5年内的因骨折导致严重伤害的风险概率</p>
									</div>
								</div>
							</div>
						</div>
						<div class="page-footer">
							<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
						</div>
					</div>
					<div class="r-fb page-wrap">
						<div class="page-head relative">
							<img class="medin-logo absolute" src="/assets/img/miyinlogo.png" alt="">
						<div class="absolute medin-logo-text">健康大数据评估报告</div>
							<dl class="am-text-right">
								<dt class="am-text-sm fontw-normal"></dt>
								<dd>
									<!-- <img src="../assets/img/xianggangtijianlogo.png" alt=""> -->
								</dd>
							</dl>
						</div>
						<h2 class="classify-tit title-before am-text-xl fontw-bold color-extr">骨密度风险评估</h2>
						<div class="fb-tscore">
							<h3 class="res-tit margin-top-4">骨密度改善建议</h3>
							<div class="density-title">骨密度值为：<span id='tscore'></span><span class="tscore-tip"> ，建议改善目标如下图：</span></div>
							<div class="density-proposal">
								<!-- (若骨密度改善：增加0.1，可降低<span>0.39%</span>骨折风险概率；增加0.2，可降低<span>0.76%</span>骨折风险概率) -->
							</div>
							<div class="density-echart-box">
								<div class="density-echart"></div>
								<!-- 处理鼠标hover 小于0部分不显示 -->
								<div class="density-echart-mask"> 
									<div class="mask-cursor cursor-pointer">
										<div class="mask-cursor-line am-hide"></div>
									</div>
								</div>
							</div>
							<div class="density-list">
								<div class="density-item yingy">
									<div class="item-title"><span>若您保持营养均衡，不贫血：</span></div>
									<div class="item-content"></div>
								</div>
								<div class="density-item qnsmoke">
									<div class="item-title"></div>
									<div class="item-content"></div>
								</div>
								<div class="density-item qndrink">
									<div class="item-title"></div>
									<div class="item-content"></div>
								</div>
							</div>
							<div class="proposal fb-diet">
								<div class="proposal-title">饮食改善建议</div>
								<div class="proposal-list">
									<div class="proposal-desc">依据您的年龄、性别、生活习惯等情况，提供关于骨折风险的建议如下：</div>
									<div class="proposal-item">
										
									</div>
								</div>
							</div>
							<div class="new-module-explain margin-top-24">
								<p>骨折多由外伤暴力而造成，例如撞击、摔倒、高处跌落的冲击等。严重的多发性骨折可导致休克，甚至危及生命。 骨折是一个结果，而造成骨折的原因是多种多样的。对于中老年人而言，因各种疾病和身体机能下降造成的贫血、眩晕、身体失衡等症状会大大增加摔倒和冲击的可能，从而增加骨折的风险。</p>
							</div>
						</div>
						
						<div class="page-footer">
							<div class="page-footer-text">本大数据分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。</div>
						</div>
					</div>
				</div>
			</div>
		
			<div style="display: none;" class="package"></div>
		</div>
		
	
	</div>
</template>

<script>
	export default{
		name: "printTemplate",
		data() {
			return {}
		},
		watch: {
			$route() {
				getData();
			}
		},
		mounted() {
			this.$nextTick(() => {
				
				// 引入静态js
				var jq = document.createElement('script');
				jq.src = "/assets/js/jquery.min.js";
					
				jq.addEventListener('load', () => {
					this.getScript('amazeui.min', () => {
						this.getScript('slider', () => {
							this.getScript('echarts.min', () => {
								this.getScript('base', () => {
									this.getScript('LodopFuncs', () => {
										this.getScript('jspdf.min', () => {
											this.getScript('html2canvas.min', () => {
												this.getScript('jszip.min', () => {
													this.getScript('FileSaver', () => {
														this.getScript('download-new', () => {
															
														});
													});
												});
											});
										});
									});
								});
							});
						});
					});
				})
				
				document.body.appendChild(jq);
	
			})
			
		},
		methods: {
			getScript(name, callback) {
				$.getScript('/assets/js/' + name + '.js',callback);
			}
		}
	}
</script>

<style scoped>
	@import "/assets/css/amazeui.min.css";
	@import "/assets/iconfont/iconfont.css";
	@import "/assets/css/base.css";
	@import "/assets/css/slider.css";
	/* @import "/assets/css/download.css"; */
	@import "/assets/css/download-new.css";
	
</style>
